import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

import { IGatsbyImageData } from 'gatsby-plugin-image';
import Gallery from '@browniebroke/gatsby-image-gallery';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Costumes from '../../components/Costumes';

type ImageSharpEdge = {
  node: {
    childImageSharp: {
      thumb: IGatsbyImageData;
      full: IGatsbyImageData;
    };
  };
};

type BasePagePropsType = {
  className?: string;
  data: {
    images: {
      edges: ImageSharpEdge[];
    };
  };
};

const TITLE = '羽織袴';
const PATH = 'haorihakama';

export const query = graphql`
  query {
    images: allFile(filter: { relativeDirectory: { eq: "haorihakama" } }, sort: { fields: name }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

const BasePage: React.FC<BasePagePropsType> = ({ className, data }) => {
  const images = data.images.edges.map(({ node }, index) => ({
    ...node.childImageSharp,
    // Generate name based on the index as caption.
    caption: `早川写真 衣装 ${index}`,
  }));
  // Override some of Lightbox options to localise labels in French
  const lightboxOptions = {
    imageLoadErrorMessage: '画像取得に失敗しました',
    nextLabel: '次へ',
    prevLabel: '前へ',
    zoomInLabel: '拡大',
    zoomOutLabel: '縮小',
    closeLabel: '閉じる',
  };

  //Add callback to Lightbox onCloseRequest
  const onClose = () => {
    console.log('Lightbox was closed');
  };

  return (
    <div className={className}>
      <Layout>
        <SEO title={TITLE} path={PATH} />
        <h2>{TITLE}</h2>
        <p>衣装を押すと拡大してご覧いただけます</p>
        <div className="gallery-wrapper">
          <Gallery
            gutter={'1px'}
            images={images}
            lightboxOptions={lightboxOptions}
            onClose={onClose}
          />
        </div>
        <Costumes />
      </Layout>
    </div>
  );
};

const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        .spacer30 {
          margin-bottom: 30px;
        }
        .gallery-wrapper {
          margin: 5px 5px 30px 5px;
        }
      }
    `;
  }}
`;
export default Page;
